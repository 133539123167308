<template>
  <div class="py-2 log_box">
    <div class="text-left text-gray-500 duration-500 path sm:mt-6 sm:mb-4">
      {{
        kdType === 1
        ? "首页-售后服务-设备绑定-物流信息"
        : kdType == 4
          ? "首页-抽奖-抽奖物流"
          : "首页-积分兑换-兑换记录详情"
      }}
    </div>
    <div class="flex px-2 text-left content" v-if="kdType == 3">
      <div class="productImg">
        <img :src="exchangeInfo.productsImage" alt v-if="exchangeInfo.productsImage" />
        <img src="../../../../assets/images/personal/personal8.png" alt v-else />
      </div>
      <div class="pl-2 text-left left_box">
        <div class="text-base font-bold ex_text">
          商品名称：{{ exchangeInfo.productName }}
        </div>
        <div class="sm:my-2 ex_text">
          商品描述：{{
            exchangeInfo.productsDescribe ? exchangeInfo.productsDescribe : "--"
          }}
        </div>
        <div class="ex_text">
          所需积分：
          <span class="text-lg font-bold text-primary">{{
            exchangeInfo.productsIntegral ? exchangeInfo.productsIntegral : "--"
          }}</span>
        </div>
      </div>
    </div>
    <div class="flex justify-between px-2 content" v-if="kdType == 4">
      <div class="flex">
        <div class="productImg">
          <img :src="selectPrize.prizeUrl" alt v-if="exchangeInfo.productsImage" />
          <img src="../../../../assets/images/personal/personal8.png" alt v-else />
        </div>
        <div class="pl-2 text-left left_box">
          <div class="text-base font-bold ex_text">
            抽奖获得{{ selectPrize.prizeName }}
          </div>
          <div class="mt-2 text-sm text-gray-500">
            {{ selectPrize.prizeName }}
          </div>
          <!-- <div
                    class="sm:my-2 ex_text"
                    >商品描述：{{ exchangeInfo.productsDescribe ? exchangeInfo.productsDescribe : '--' }}</div>-->
          <!-- <div class="ex_text">
                    所需积分：
                    <span
                        class="text-lg font-bold text-primary"
                    >{{ exchangeInfo.productsIntegral ? exchangeInfo.productsIntegral : '--' }}</span>
                    </div>-->
        </div>
      </div>
      <div>
        <div v-if="selectPrize.status && selectPrize.status != 3"
          class="flex items-center justify-center px-4 py-1 text-sm text-white rounded bg-primary"
          @click="receiptOfGoodsFunc">
          确认收货
        </div>
      </div>
    </div>
    <div class="my-2 mt-6 text-left sm:text-base" v-if="kdType == 3 || kdType == 4">
      物流信息
    </div>
    <div v-if="logistics.kdId && logistics.kdId != null">
      <div class="duration-500 sm:pb-6 message">
        <div class="text-left">订单编号：{{ logistics.orderNum }}</div>
        <div class="text-left duration-500 mes_bottom sm:mt-2">
          收货地址：{{ logistics.addressDetail }}
        </div>
      </div>
      <div class="duration-500 sm:mt-6 timeline">
        <el-timeline>
          <el-timeline-item :timestamp="item.AcceptTime" placement="bottom" v-for="item in logistics.infoData.Traces"
            :key="item.AcceptTime">
            <div class="flex justify-start duration-500">
              <div>
                <div class="text-left duration-500 line_content">
                  {{ item.AcceptStation }}
                </div>
              </div>
            </div>
          </el-timeline-item>
          <!-- <el-timeline-item timestamp="2018/4/3" placement="bottom">
                    <div class="flex justify-start duration-500">
                        <div class="text-left ligth_text">【上海市】快件离开【上海三六灶网点】已发往【上海普通转运中心】</div>
                    </div>
                </el-timeline-item>
                <el-timeline-item timestamp="2018/4/2" placement="bottom">
                    <div class="flex justify-start duration-500">
                        <div class="text-left ligth_text">【上海市】快件离开【上海三六灶网点】已发往【上海普通转运中心】</div>
                    </div>
                    </el-timeline-item>-->
        </el-timeline>
      </div>
    </div>
    <el-empty :image-size="200" v-else></el-empty>
  </div>
</template>

<script>
import { CodeToText } from "element-china-area-data";
export default {
  data() {
    return {
      maintainId: "",
      kdType: "",
      logistics: {
        kdId: null,
        kdNum: "",
        kdType: null,
        maintainId: null,
        cityCode: [],
        address: "",
        addressDetail: "",
        remark: "",
        withShipperCode: "",
        orderNum: "",
        infoData: {
          DeliveryMan: "",
          DeliveryManTel: "",
          EBusinessID: "",
          Location: "",
          LogisticCode: "",
          ShipperCode: "",
          State: "",
          StateEx: "",
          Traces: [],
        },
      },
      exchangeInfo: {
        productName: "",
        productsImage: "",
        orderNum: "",
        productsDescribe: "",
        productsIntegral: "",
      },
      selectPrize: {},
      receiptOfGoods: false,
    };
  },
  created() {
    console.log(this.$route.query);
    let { maintainId, kdType } = this.$route.query;
    this.maintainId = maintainId;
    this.kdType = kdType;
    this.getLogistics();
    if (this.kdType == 3) {
      this.exchangeDetail();
    }
    if (this.kdType == 4) {
      let selectPrize = JSON.parse(localStorage.getItem("selectPrize"));
      this.selectPrize = Object.assign({}, this.selectPrize, selectPrize);
      console.log(this.selectPrize);
    }
  },
  methods: {
    // 获取物流
    getLogistics() {
      this.$request({
        method: "get",
        url: this.$requestPath.logistics,
        params: {
          maintainId: this.maintainId,
          kdType: this.kdType,
        },
      })
        .then((res) => {
          console.log("获取物流信息", res);
          if (res.code == 0) {
            if (res.data.info) {
              res.data.infoData = JSON.parse(res.data.info);
              res.data.infoData.Traces.reverse();
              console.log(res.data);

              res.data.addressDetail =
                this.getCodeToText(res.data.cityCode) + res.data.address;
              Object.assign(this.logistics, res.data);
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log("数据请求失败，请稍后再试", error);
        });
    },
    // 获取积分商品数据
    exchangeDetail() {
      this.$request({
        method: "get",
        url: this.$requestPath.exchangeDetail,
        params: {
          exchangeId: this.maintainId,
        },
      })
        .then((res) => {
          console.log("获取积分兑换纪录详情", res);
          if (res.code == 0) {
            Object.assign(this.exchangeInfo, res.data);
          }
        })
        .catch((error) => {
          console.log("获取积分兑换纪录", error);
        });
    },

    // 地址转换
    getCodeToText(codeStr) {
      let codeArray = codeStr.split(",");
      let area = "";
      switch (codeArray.length) {
        case 1:
          area += CodeToText[codeArray[0]];
          break;
        case 2:
          area += CodeToText[codeArray[0]] + CodeToText[codeArray[1]];
          break;
        case 3:
          area +=
            CodeToText[codeArray[0]] +
            CodeToText[codeArray[1]] +
            CodeToText[codeArray[2]];
          break;
        default:
          break;
      }
      return area;
    },
    // 确认收货
    receiptOfGoodsFunc() {
      this.$request({
        method: "put",
        url: this.$requestPath.receiptOfGoods,
        data: {
          luckDrawRecordId: this.selectPrize.luckDrawRecordId,
        },
      })
        .then((res) => {
          console.log("确认收货", res);
          if (res.code == 0) {
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "确认收货成功",
            });
            setTimeout(() => {
              this.selectPrize.status = 3;
              localStorage.setItem(
                "selectPrize",
                JSON.stringify(this.selectPrize)
              );
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((error) => {
          console.log("数据请求失败，请稍后再试", error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.log_box {
  padding: 0 var(--margin-base);
  min-height: var(--min-height);
}

.message {
  border-bottom: 0.0625rem solid var(--border-base);
}

.ligth_text {
  color: rgb(128, 128, 128);
}

@media screen and (max-width: 640px) {
  .productImg {
    width: 5.625rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .left_box {
    width: calc(100% - 5.625rem);
  }
}

@media screen and (min-width: 640px) {
  .productImg {
    width: 12.5rem;

    img {
      width: 100%;
      height: auto;
    }
  }

  .left_box {
    width: calc(100% - 12.5rem);
  }
}
</style>
<style>
.el-timeline-item__timestamp {
  display: flex;
  justify-content: flex-start;
}

.el-timeline .el-timeline-item:nth-child(1) .el-timeline-item__node {
  background: red;
}

@media screen and (max-width: 640px) {
  .path {
    margin: 0.625rem 0;
  }

  .message {
    padding-bottom: 0.625rem;
    font-size: 0.8125rem;
  }

  .mes_bottom {
    margin-top: 0.375rem;
  }

  .timeline {
    margin-top: 1rem;
  }

  .line_content {
    font-size: 0.8125rem;
  }
}
</style>
